import React from "react";
import { ScaleLoader } from "react-spinners";
import { Button, Grid, Modal } from "semantic-ui-react";

function FormModal({
  open,
  setOpen,
  to,
  loading,
  error,
  modalMessage,
  success,
  closeable,
}) {
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      closeOnDimmerClick={closeable}
      closeOnEscape={closeable}
    >
      <Modal.Header>Sending your form to {to}</Modal.Header>
      <Modal.Content>
        <Grid centered>
          {loading && <ScaleLoader />}
          {(error || success) && modalMessage}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        {error && (
          <Button content="OK" negative onClick={() => setOpen(false)} />
        )}
        {success && (
          <Button
            content="Finished"
            labelPosition="right"
            icon="checkmark"
            onClick={() => setOpen(false)}
            positive
          />
        )}
      </Modal.Actions>
    </Modal>
  );
}

export default FormModal;
