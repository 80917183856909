import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "firebase/firestore";
import Admin from "./Admin";
import BodyForm from "./BodyForm";
import Home from "./Home";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/form/:to">
          <BodyForm />
        </Route>
        <Route path="/admin">
          <Admin />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
