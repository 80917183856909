import React, { useState } from "react";
import { Divider, Form, Grid, Header, Segment } from "semantic-ui-react";
import DisplayURL from "./DisplayURL";

const Home = () => {
  const [form, setForm] = useState({ email: "" });

  const handleChange = (e, { name, value }) => {
    setForm({ ...form, [name]: value });
  };

  return (
    <Grid textAlign="center" verticalAlign="middle" style={{ height: "100vh" }}>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal">
          Hurts Where?
          <Header.Subheader>
            Easily generate reports on client pain.
          </Header.Subheader>
        </Header>
        <Segment stacked textAlign="left">
          <Form>
            <Form.Input
              name="email"
              label="1. Where do you want the report sent?"
              fluid
              icon="at"
              placeholder="Email"
              iconPosition="left"
              value={form.email}
              onChange={handleChange}
            />
          </Form>
          <Divider hidden />
          <DisplayURL visible={!!form.email} email={form.email} />
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default Home;
