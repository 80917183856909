/**
 * Original from: https://stackoverflow.com/questions/21961839/simulation-background-size-cover-in-canvas
 * Original By Ken Fyrstenberg Nilsen
 *
 * Note: img must be fully loaded or have correct width & height set.
 */
export const drawImage = (input) => {
  var { ctx, img } = input;
  var { nh, nw, ox, oy } = getImageDim(input);

  ctx.drawImage(input.img, 0, 0, img.width, img.height, ox, oy, nw, nh);
};

export const getImageDim = ({ ctx, img, x, y, offsetX, offsetY } = {}) => {
  // Defaults
  if (typeof x !== "number") x = 0;
  if (typeof y !== "number") y = 0;
  //   if (typeof w !== "number") nw = ctx.canvas.width;
  //   if (typeof h !== "number") nh = ctx.canvas.height;
  if (typeof offsetX !== "number") offsetX = 0.5;
  if (typeof offsetY !== "number") offsetY = 0.5;

  var iw = img.width,
    ih = img.height,
    nw = ctx.canvas.width,
    r = nw / iw,
    nh = ih * r;

  // Too tall?
  if (nh > ctx.canvas.height) {
    nh = ctx.canvas.height;
    var nR = ctx.canvas.height / ih;
    nw = iw * nR;
  }

  // Find offsets
  var ox = ctx.canvas.width / 2 - nw / 2;
  var oy = ctx.canvas.height / 2 - nh / 2;

  return { nh, nw, ox, oy };
};
