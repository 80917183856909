import React, { useRef, useState } from "react";
import {
  Container,
  Divider,
  Grid,
  Header,
  Segment,
  Button,
  Form,
  Table,
} from "semantic-ui-react";
import bodyImage from "./humanBody.png";
import ReactDraw from "./ReactDraw";
import { useParams } from "react-router-dom";
import FormModal from "./FormModal";

const options = [
  { key: "0", text: "0", value: "0" },
  { key: "1", text: "1", value: "1" },
  { key: "2", text: "2", value: "2" },
  { key: "3", text: "3", value: "3" },
  { key: "4", text: "4-5", value: "4-5" },
  { key: "5", text: "6", value: "6" },
  { key: "6", text: "7-9", value: "7-9" },
  { key: "7", text: "10", value: "10" },
];

const BodyForm = () => {
  const drawRef = useRef();
  const date = new Date();
  const to = decodeURIComponent(useParams().to);
  console.log(to);

  // Demographic data
  const [demoData, setDemoData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    age: "",
    date: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
    painWorst: "",
    painBest: "",
  });

  // Form state
  const [formState, setFormState] = useState({
    modalOpen: false,
    loading: false,
    success: false,
    error: false,
    closeable: true,
    attempt: false,
  });

  const valid =
    demoData.firstName &&
    demoData.lastName &&
    demoData.email &&
    demoData.age &&
    demoData.date &&
    demoData.painWorst &&
    demoData.painBest;

  const handleChange = (e, { name, value }) => {
    setDemoData({ ...demoData, [name]: value });
  };

  const handleSubmit = async (imgURL) => {
    const url =
      "https://us-central1-health-form-97634.cloudfunctions.net/sendReport";
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        demoData,
        imgURL,
        to,
      }),
    };

    setFormState({
      ...formState,
      loading: true,
      modalOpen: true,
      error: false,
      success: false,
      closeable: false,
    });

    try {
      const response = await fetch(url, requestOptions);
      if (response.status >= 200 && response.status <= 299) {
        setFormState({
          ...formState,
          loading: false,
          modalOpen: true,
          modalMessage: "Your form has been sent!",
          success: true,
          closeable: true,
        });
      } else {
        throw Error(response.statusText);
      }
    } catch (e) {
      setFormState({
        ...formState,
        loading: false,
        modalOpen: true,
        modalMessage:
          "Something went wrong. Please try again in a few minutes.",
        error: true,
        closeable: true,
      });
    }
  };

  return (
    <div>
      <FormModal
        open={formState.modalOpen}
        setOpen={(s) => setFormState({ ...formState, modalOpen: s })}
        to={to}
        loading={formState.loading}
        error={formState.error}
        success={formState.success}
        modalMessage={formState.modalMessage}
        closeable={formState.closeable}
      />
      <Container>
        <Divider hidden />
        <Grid
          textAlign="center"
          style={{ height: "100vh" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{}}>
            <Header as="h2" color="teal" textAlign="center">
              Hurts Where?
            </Header>
            <Form size="large">
              <Segment stacked textAlign="left">
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    name="firstName"
                    label="First name"
                    placeholder="First name"
                    value={demoData.firstName}
                    onChange={handleChange}
                    required
                  />
                  <Form.Input
                    fluid
                    name="lastName"
                    label="Last name"
                    placeholder="Last name"
                    value={demoData.lastName}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    name="email"
                    label="Email"
                    placeholder="example@example.com"
                    value={demoData.email}
                    onChange={handleChange}
                    required
                  />
                  <Form.Input
                    fluid
                    name="age"
                    label="Age"
                    placeholder="Age"
                    value={demoData.age}
                    onChange={handleChange}
                    required
                  />
                  <Form.Input
                    fluid
                    name="date"
                    label="Date"
                    placeholder="MM/DD/YY"
                    value={demoData.date}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Segment textAlign="left">
                  Mark these drawings according to where you are hurt or feel
                  pain. For example, if the right side of your neck hurts, mark
                  the drawing on the right side of the neck.
                  <Divider hidden />
                  <ReactDraw
                    ref={drawRef}
                    imgSrc={bodyImage}
                    lazyRadius={0}
                    brushRadius={3}
                    brushColor={"#e33229"}
                    catenaryColor={"#e33229"}
                    hideGrid
                  />
                  <Form.Group inline>
                    <Button
                      icon="undo"
                      content="Undo"
                      onClick={() => {
                        drawRef.current.undo();
                      }}
                    />
                    <Button
                      icon="trash"
                      content="Clear"
                      onClick={() => {
                        drawRef.current.clear();
                      }}
                    />
                  </Form.Group>
                </Segment>
                <Divider hidden />
                <Form.Group widths="equal">
                  <Form.Select
                    fluid
                    name="painBest"
                    label="How would you rate your pain level at its best?"
                    placeholder="Pain level"
                    value={demoData.painBest}
                    onChange={handleChange}
                    options={options}
                    required
                  />
                  <Form.Select
                    fluid
                    name="painWorst"
                    label="How would you rate your pain level at its worst?"
                    placeholder="Pain level"
                    value={demoData.painWorst}
                    onChange={handleChange}
                    options={options}
                    required
                  />
                </Form.Group>
                <Table celled>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>0</Table.Cell>
                      <Table.Cell>No pain</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>1</Table.Cell>
                      <Table.Cell>
                        Mild pain. You are aware of it, but it doesn't bother
                        you.
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>2</Table.Cell>
                      <Table.Cell>
                        Moderate pain that you can tolerate without medication.
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>3</Table.Cell>
                      <Table.Cell>
                        Moderate pain that requires medication to tolerate.
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>4-5</Table.Cell>
                      <Table.Cell>
                        More severe pain. You begin to feel antisocial.
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>6</Table.Cell>
                      <Table.Cell>Severe pain.</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>7-9</Table.Cell>
                      <Table.Cell>Intensely severe pain.</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>10</Table.Cell>
                      <Table.Cell>
                        Most severe pain. Emergency room care.
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>

                <Form.Button
                  content="Submit"
                  color="teal"
                  fluid
                  size="large"
                  disabled={!valid}
                  onClick={() => handleSubmit(drawRef.current.convertToImage())}
                />
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  );
};

export default BodyForm;
