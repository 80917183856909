import React from "react";
import ReactDOM from "react-dom";
import { FirebaseAppProvider } from "reactfire";
import "semantic-ui-css/semantic.min.css";
import App from "./components/App";

var firebaseConfig = {
  apiKey: "AIzaSyBgEyR3XOHjbspt5bllpzPX0vt5E3mMyTQ",
  authDomain: "health-form-97634.firebaseapp.com",
  projectId: "health-form-97634",
  storageBucket: "health-form-97634.appspot.com",
  messagingSenderId: "477888122029",
  appId: "1:477888122029:web:979679c61951c755938a24",
};

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <App />
  </FirebaseAppProvider>,
  document.getElementById("root")
);
