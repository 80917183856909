import React, { useEffect, useRef, useState } from "react";
import AnimateHeight from "react-animate-height";
import { Divider, Label } from "semantic-ui-react";

import "./DisplayURL.css";

const DisplayURL = ({ visible, email }) => {
  const [height, setHeight] = useState(0);
  const [copyVisible, setCopyVisible] = useState(false);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (!visible) setClicked(false);
    setHeight(visible ? "auto" : 0, [visible]);
  }, [visible]);
  const inputRef = useRef();

  const handleCopy = () => {
    inputRef.current.select();
    document.execCommand("copy");
    setClicked(true);
    setCopyVisible(!copyVisible);
  };

  return (
    <AnimateHeight
      className="ui form"
      id="example-panel"
      duration={500}
      height={height} // see props documentation below
    >
      <div className="field" style={{ height: { height } }}>
        <label>2. Copy the link</label>
        <div className="ui fluid action input">
          <input
            name="email"
            placeholder="Email"
            type="text"
            value={`https://hurtswhere.com/form/${encodeURIComponent(email)}`}
            ref={inputRef}
            readOnly
          />
          <button
            className="ui teal icon right labeled button"
            onClick={handleCopy}
          >
            <i aria-hidden="true" className="copy icon"></i>
            Copy
          </button>
          <Label
            pointing
            className={
              clicked ? (copyVisible ? "fade1" : "fade2") : "invisible"
            }
            style={{
              position: "absolute",
              zIndex: 1,
              right: 25,
              top: "100%",
            }}
          >
            Copied
          </Label>
        </div>
      </div>
      <Divider hidden />
      <b>3. Send it to whomever you'd like!</b>
    </AnimateHeight>
  );
};

export default DisplayURL;
